.linkedin-logo {
    width: 60px;
  }
  
  .socials-container {
    width: 200px;
    height: 55px;
    display: flex;
    justify-content: center; 
    align-items: center;
    @media (max-width: 500px) {
      width: 150px;
      height: 25px;
      }
  }
  
  .buttons-socials {
    display:flex;
    align-items: center;
    gap: 15px;
 
  
  }
  
  .github, .linkedin, .envelope {
    width: 30px;
  }

  
  .container-git, .container-linkedin, .container-envelope {
    background-color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    display: grid;
    place-items: center;
    box-shadow: 0 0 50px 20px rgba(0, 0, 0, .1);
    cursor: pointer;
    line-height: 1;
    transition-property: transform;
    transition-duration: 400ms;
  
    &:hover {
      transform: scale(1.10);
    }
  }
  
  
.card {
  position: relative;
  width: 300px;
  height: 470px;
  box-shadow: 10px 10px 12px -3px rgba(0, 0, 0, 0.4);
  border-radius: 15px;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  padding: 25px;
  overflow: hidden;

  @media (max-width: 400px) {
    width: 80%;
    height: 435px;
  }
}

.card-text {
  @media (max-width: 400px) {
   font-size: 0.9em;
         
     }
}

.image-container {
  position: relative;
 
}

.card-image {
  position: relative;
  height: 201px;
  max-width: 100%;
  border-radius: 15px;
  transition: filter 0.3s;
  @media (max-width: 400px) {
    height: 160px;
          
      }
  
}
.image-container:hover .card-image {
  filter: brightness(0.6);
}

.image-container:hover .icons {
  display: flex;
}
.icons {
 display: none;
 gap: 30px;
  position: absolute;
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%); 

}

.icon-card {

  width: 30px;
  padding: 5px;
  background: white;
  border-radius: 20px;
  cursor: pointer;
  transition: none;
}

.card-infos {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0;
}

.card-title {
text-align: left;
padding-bottom: 0;
  font-size: 1.5em;
  color: rgb(0, 0, 159);
  @media (max-width: 400px) {
    font-size: 1.2em;
          
      }
}

.tech-buttons {
  display: flex;
}
.dark-mode {
$background-color: #1a1625;
$text-color: whitesmoke;


h2, nav a, p, .text-container {
  color: $text-color
}

.name, nav a {
    &:hover {
      color:  #ba9ffb;
    }
}

h3 {
    color : #a688fa;
}

.card-title {
   color: #ba9ffb;
}

.home {
    background: none;
    background-color: $background-color;
    @import url('https://fonts.googleapis.com/css?family=Montserrat:700');


// Animation background : 

    .cube {
      position: absolute;
      top: 80vh;
      left: 25vw;
      width: 5px;
      height: 5px;
      border: solid 1px darken(#ba9ffb, 8%);
      transform-origin: top left;
      transform: scale(0) rotate(0deg) translate(-50%, -50%);
      animation: cube 12s ease-in forwards infinite;
      
      &:nth-child(2n) {
        border-color: lighten(#ba9ffb, 10%);
      }
      
      &:nth-child(2) {
        animation-delay: 2s;
        left: 8vw;
        top: 25vh;
      }
      
      &:nth-child(3) {
        animation-delay: 4s;
        left: 85vw;
        top: 70vh;
      }
      
      &:nth-child(4) {
        animation-delay: 6s;
        left: 90vw;
        top: 35vh;
      }
      
      &:nth-child(6) {
        animation-delay: 10s;
        left: 50vw;
        top: 10vh;
      }
    }
    
    @keyframes cube {
      from {
        transform: scale(0) rotate(0deg) translate(-50%, -50%);   
        opacity: 1;
      }
      to {
        transform: scale(20) rotate(960deg) translate(-50%, -50%); 
        opacity: 0;
      }
    }

}

.firstname, .cursor {
    color: #7a5af5
}

.mouse {
    border: 3px solid #7a5af5;
}

.scroll-btn .mouse > * {
    background-color: #7a5af5;
}

.underline {
    border: 4px solid #a688fa;
}

.square {
    background-color: #908d96;
    color: $text-color;
    transition: 0.6s;
&:hover {
    background-color: #a688fa;
}
}

.button-cv {
    background-color: #7a5af5;
    &:hover {
        background-color: whitesmoke;
        color: #7a5af5;
    }
}

.contact-block, .about-container, .projects-container, header  {
    background-color: $background-color;
    color: $text-color;
  }

.footer {
    background-color: $background-color;
    color: $text-color;
}

input, .message {
    background-color: whitesmoke
}

.submit {
    background-color: #7a5af5;
    color: $text-color;
}

.card, .form-container {
    background-color: #2f2b3a;
    color: $text-color
}


.tech-logo {
    background-color: rgba(245, 245, 245, 0.945);
}

.menu__box {
  background-color: $background-color
}

.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {
  background-color: #a688fa;
}
}

#projects-title {
    padding-top: 30px;
}

.projects-container {
    padding: 60px 0;
}

// Effet apparitions cards

.reveal {
    position: relative;
    opacity: 0;
  }
  .reveal.active {
    opacity: 1;
  }
  
.active.fade-bottom {
    animation: fade-bottom 1s ease-in;
  }

@keyframes fade-bottom {
    0% {
      transform: translateY(50px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

.cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 60px;
    margin: 80px 0;
    @media (max-width: 400px) {
     margin: 80px 20px 80px 24px;
    }
}

.tech-logo {
    
    width: 25px;
    border: 1px solid rgba(128, 128, 128, 0.132);
    box-shadow: 4px 4px 4px -4px rgba(0,0,0,0.4);
    padding: 6px;
    margin-left: 5px;
    border-radius: 20px;
&:hover {
    opacity: 0.6;
}
   
}


.footer {
    display: flex;
    flex-direction: column;
    background-color: darkblue;
    height: 120px;
    color: white;
    font-size: 2em;
    text-align: CENTER;
    justify-content: center;
    align-items: CENTER;
}

.custom-container-git {
    // Styles spécifiques à container-git
    border-radius: 20px;
  }
  
  .custom-container-linkedin {
    // Styles spécifiques à container-linkedin
    border-radius: 20px;
  }
  
  .custom-container-envelope {
    // Styles spécifiques à container-envelope
    border-radius: 20px;
  }

.copyright {
    font-size: 0.5em;
    margin-top: 5px;
}

.socials-footer {
    display: flex;
    gap: 20px;
}

.logo-footer {
    width: 30px;
    padding: 3px;
    border-radius: 20px;
    background-color: white;
    cursor: pointer;
    &:hover {
     opacity: 0.6;
    }
}
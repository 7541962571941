#contact-title {
    padding-top: 50px;
}
.contact-block {
    background-color: ghostwhite;
    padding: 60px 0;
}
.contact-container {
    margin: 0 50px;
    display: flex;
    justify-content: space-around;
    margin-top: 80px;
    @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
        
    }
}

.contact-text {
    @media (max-width: 400px) {
      font-size: 0.8em;
             
         }
}

.contact-content {
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
    font-size: 1.5em;
    font-weight: 600;
    color: darkblue
}


.mail-picture {
    width: 80%;
   transform:rotate(-10deg);    
}

.form-container {
    width: 320px;
    height: 400px;
    background-color: darkblue;
    border-radius: 10px;
    padding: 40px;
    box-shadow: 10px 10px 13px -7px rgba(0,0,0,0.4);
    @media (max-width: 900px) {
        width: 60%;
            
        }
        @media (max-width: 400px) {
           padding: 20px;
           height: 350px;
           width: 95%;
                
            }
}

form {
    display: flex;
    flex-direction: column;
    gap: 25px;

}


.message {
    height: 180px;
    width: 100%;
    border-radius: 5px;
    outline: none;
    border: none;
    padding: 10px 0;
    @media (max-width: 400px) {
        height: 150px;
             
         }
   
}

form input {
    border: none;
   border-radius: 5px;
    background-color:white;
    box-shadow: 10px 10px 13px -7px rgba(0,0,0,0.1);
    width: 100%;
    height: 50px;
    outline: none;
    @media (max-width: 400px) {
        height: 40px;
             
         }
}


::placeholder {
    display: flex;
    color: rgba(0, 55, 255, 0.619);
    font-size: 1.1em;
    padding-left: 5px;
}


.submit {
    width:100px;
    display: flex;
    justify-content: center;
    align-self: flex-end;
    height: 45px;
    border-radius: 10px;
    color: darkblue;
    font-weight: bold;
    font-size: 1.1em;
    cursor: pointer;
    transition: transform 400ms;
&:hover {
    transform:scale(1.05);
}

@media (max-width: 400px) {
    height: 30px;
    font-size: 0.9em;
         
     }
}


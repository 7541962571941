.about-container {
    padding: 30px 20px;
    background-color: ghostwhite;
 
}

#about-title {
    padding-top: 50px;
}


.underline {
    border-bottom: 4px solid orange;
    margin: 0 auto 15px;
    width: 70px;
    border-radius: 5px;
}

.about-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 6rem;
    margin: 80px 35px;
    @media (max-width: 900px) {
    grid-template-columns: 1fr;

    }
}

.skills-content {
    padding-top: 20px;
display: flex;
flex-direction: column;
align-items: center;
}

.skills {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.about-pic {
    display: flex;
    justify-content: center;
    width: 330px;
    padding-top: 55px;
    @media (max-width: 400px) {
        width: 260px;
                 
             }
}

.square {
    display: inline-block;
    color: rgba(0, 0, 0, 0.491);
   font-size: 1.5rem;
    padding: 15px;
    border: none;
    background-color: rgba(164, 164, 164, 0.358);
    margin-bottom: 3px;
    border-radius: 4px;
    font-size: 1.1em;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    transition: 0.6s;
&:hover {
    background-color: rgba(0, 0, 139, 0.774);
    color: whitesmoke;
    scale: 1.10;
}

@media (max-width: 400px) {
    padding: 10px;
    font-size: 0.9em;
             
         }
}


.presentation {
    line-height: 1.5;
    text-align: left;
    @media (max-width: 400px) {
     font-size: 0.9em;
              
          }

}

.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: fixed;
    background: white;
    z-index: 1;
}

.name {
  font-size: 1em;
&:hover {
color: blue;
transition: color 0.4s;
cursor: pointer;
}
@media (max-width: 450px) {
  font-size: 0.8em;
   }
}

.header-content {
    display: flex;
    align-items: center;
    font-size: 1.2em;
    font-weight: bold;
}

nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    padding-right: 15px;
    @media (max-width: 900px) {
        display: none; // Masque la navigation principale
    }
}

nav a {
    color: rgba(0, 0, 0, 0.872);
  font-weight: 600;
    font-size: 1.1em;
    text-transform:uppercase;
    text-decoration: none;
    transition: color 0.4s;
&:hover {
  color: blue;  
}
}

.nav-light {
  display: flex;
  gap: 20px;
}

.light-mode {
  display: flex;
  align-items: center;
  @media (max-width: 900px) {
    transform: translateX(-60px);
  }
}

.button-cv {
    color: white;
    font-size: 0.8em;
    background-color: orange;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    padding: 10px;
    transition: transform 400ms;
    &:hover{
    transform: scale(1.10);
    color: white;
    }
}

.logo {
width: 70px;
}


// Burger menu
@media (max-width: 900px) {
  .hamburger-menu {
    display: block; 
    z-index: 1000;
  }
}

@media (min-width: 901px) {
    .hamburger-menu {
      display: none; 
    }
}


#menu__toggle {
    opacity: 0;
  }
  #menu__toggle:checked + .menu__btn > span {
    transform: rotate(45deg);
  }
  #menu__toggle:checked + .menu__btn > span::before {
    top: 0;
    transform: rotate(0deg);
  }
  #menu__toggle:checked + .menu__btn > span::after {
    top: 0;
    transform: rotate(90deg);
  }
  #menu__toggle:checked ~ .menu__box {
    left: 0 !important;
  }
  .menu__btn {
    position: fixed;
    top: 33px;
    right: 20px;
    width: 26px;
    height: 26px;
    cursor: pointer;
    z-index: 1;
  }
  .menu__btn > span,
  .menu__btn > span::before,
  .menu__btn > span::after {
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #142ca5;
    transition-duration: .25s;
  }
  .menu__btn > span::before {
    content: '';
    top: -8px;
  }
  .menu__btn > span::after {
    content: '';
    top: 8px;
  }
  .menu__box {
    display: block;
    position: fixed;
    top: 70px;
    left: 100%;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 80px 0;
    list-style: none;
    background-color: #091e61;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, .4);
    transition-duration: .25s;
    text-align: center;
    text-transform: uppercase;
  }
  .menu__item {
    display: block;
    padding: 12px 24px;
    color: #ffffff;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 600;
    text-decoration: none;
    transition-duration: .25s;
  }
  .menu__item:hover {
    background-color: #cfd8dc30;
  }
